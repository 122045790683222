let endpoint;
let s3;
// load the correct aws config options

switch (process.env.REACT_APP_VERCEL_ENV) {
    case 'production':
        endpoint = 'https://api.thereliefconsultants.com/api/v1';
        s3 = {
            endpoint: 'https://trc-erc-stackprod-filess3bucket-1b5q2jbcvvzlj.s3.amazonaws.com',
        };
        break;
    case 'development':
    case 'preview':
        endpoint = 'https://dev.api.thereliefconsultants.com/api/v1';
        s3 = {
            endpoint: 'https://trc-erc-stackdev-filess3bucket-hsvx9maax4ng.s3.amazonaws.com',
        };
        break;
    default:
        endpoint = 'http://localhost:3000/api/v1';
        s3 = {
            endpoint: 'https://trc-erc-stackdev-filess3bucket-hsvx9maax4ng.s3.amazonaws.com',
        };
        break;
}

const ApiResource = { endpoint, s3 };

export default ApiResource;
