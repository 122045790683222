import React, { useState } from 'react';
// Modules
import axios from 'axios';
import { Storage } from 'aws-amplify';
// MUI
import { Box, LinearProgress, Typography } from '@mui/material';
import { CloudUploadSharp } from '@mui/icons-material';
import Dropzone from 'react-dropzone';
// Lottie
import Lottie from 'lottie-react';
import UploadAnimation from 'assets/animations/upload.json';
// Styles
import { AppColors } from 'theme';
import useStyles from './styles';
import ApiResource from 'integrations/api/api_resource';

export default function FileInput({ onUpload, presigned, appendKey = '', onGetPresigned, onError, ...props }) {
    const classes = useStyles(props);
    const [uploading, setUploading] = useState(false);
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState({
        loaded: 0,
        total: 1,
    });
    const [maxSizeError, setMaxSizeError] = useState(false);

    async function upload(files) {
        if (file) handleClear();

        setUploading(true);
        try {
            setUploadProgress({
                length: files.length,
                index: 1,
                loaded: 0,
                total: 1,
            });

            const fArr = [];
            for (let i = 0; i < files.length; i++) {
                const _file = files[i];
                if (_file.size > 100000000) {
                    setMaxSizeError(true);
                    throw new Error('Max file size of 100mb reached');
                } else if (maxSizeError) setMaxSizeError(false);

                const key = `${appendKey}${_file.name}`;
                if (!presigned) throw new Error('Only presigned allowed');

                const presignedUrl = await onGetPresigned(key, _file.type);
                await axios.put(presignedUrl, _file, {
                    params: {
                        name: key,
                    },
                    headers: {
                        'Content-Type': _file.type,
                    },
                    onUploadProgress: (progress) =>
                        setUploadProgress({
                            length: files.length,
                            index: i + 1,
                            loaded: progress.loaded,
                            total: progress.total,
                        }),
                });

                fArr.push({
                    name: key,
                    filename: _file.name,
                    key: `${ApiResource.s3.endpoint}/${key}`,
                    type: _file.type,
                });
            }
            onUpload(fArr);
        } catch (error) {
            console.log(error);
            onError?.(error);
        }
        setUploading(false);
    }

    async function handleClear() {
        // const _file = file;
        setFile(null);

        /** need to find a solution so that users can only delete their keys....? */
        // try {
        //     await Storage.remove(_file.name);
        // } catch (error) {
        //     // doesn't matter
        // }
    }

    return (
        <>
            {uploading && (
                <Box className={classes.dropBox}>
                    <Lottie animationData={UploadAnimation} className={classes.animation} />
                    <LinearProgress
                        className={classes.progress}
                        variant="determinate"
                        color="primary"
                        value={(uploadProgress.loaded / uploadProgress.total) * 100}
                    />
                    <Typography variant="font5">
                        Uploading... {uploadProgress.index} of {uploadProgress.length}
                    </Typography>
                </Box>
            )}
            {!uploading && (
                <Dropzone maxFiles={5} onDrop={upload}>
                    {({ getRootProps, getInputProps }) => (
                        <Box className={classes.dropBox} {...getRootProps()}>
                            <input {...getInputProps()} />

                            <Typography
                                variant="font2"
                                sx={{
                                    marginTop: 1,
                                    marginBottom: 0.5,
                                }}
                            >
                                Select File(s) to Upload
                            </Typography>
                            <Typography
                                variant="font5"
                                sx={{
                                    color: AppColors.Gray0,
                                }}
                            >
                                or Drag and Drop, Copy and Paste Files
                            </Typography>
                            <Typography
                                variant="font5"
                                sx={{
                                    color: maxSizeError ? AppColors.Error : AppColors.Gray1,
                                }}
                            >
                                Max File Size: 100mb
                            </Typography>
                        </Box>
                    )}
                </Dropzone>
            )}
        </>
    );
}
