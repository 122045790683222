import React, { useState } from 'react';
import { Box, Button, FormControl, FormControlLabel, InputAdornment, Paper, Radio, RadioGroup, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { ChevronLeft } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import useStyles from './styles';
import NumericInput from 'components/inputs/NumericInput';

export default function Signatures({ form, onBack, onError, onContinue }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);

    async function handleContinue(data) {
        setSubmitting(true);
        try {
            const response = await axios.patch(
                `${ApiResource.endpoint}/self-employment`,
                {
                    step: 6,
                },
                {
                    headers: {
                        'x-auth': form.authToken,
                    },
                },
            );

            onContinue(response.data.form);
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    return (
        <form>
            <Box className={classes.root}>
                <Typography variant="h5" fontWeight={'400'} sx={{ marginBottom: 1 }}>
                    Final Step! Signatures
                </Typography>
                <Typography variant="font2" fontWeight={'400'} sx={{ marginBottom: 4 }}>
                    In order for us to get started working on your self employment credit we will need you to sign a few work order
                    documents. You'll receive an email via docusign.
                </Typography>

                <Button>Send Docusign Document</Button>

                <Typography>
                    After you sign the document, click the refresh button, and then continue to the application submission!
                </Typography>

                <Box className={classes.controls} sx={{ marginTop: 6 }}>
                    <Button onClick={onBack} startIcon={<ChevronLeft />}>
                        Back
                    </Button>
                    <LoadingButton loading={submitting} variant="contained">
                        Continue
                    </LoadingButton>
                </Box>
            </Box>
        </form>
    );
}
