import { makeStyles } from '@mui/styles';

import Background from 'assets/images/backgrounds/pattern.svg';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {},

    docListItem: {
        backgroundColor: 'white',
        paddingLeft: 12,
        paddingRight: 12,
        marginBottom: theme.spacing(1),
        borderRadius: 2,
        border: '1px solid rgba(0,0,0,0.1)',
    },

    form: {
        width: '100%',

        '& > div:not(:last-child)': {
            marginBottom: theme.spacing(3),
        },
    },

    formText: {
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
            marginLeft: theme.spacing(1),
            fontSize: 20,
        },
    },
    formField: {
        marginBottom: theme.spacing(3),

        '& > p': {
            marginBottom: 4,
        },
    },

    controls: {
        paddingTop: theme.spacing(3),
        borderTop: `1px solid rgba(0,0,0,0.2)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    disqualImage: {
        maxWidth: '100%',
    },
    image: {
        maxWidth: '100%',
    },
}));

export default useStyles;
