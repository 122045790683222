import React, { useState } from 'react';
import { Box, Button, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import useStyles from './styles';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import { ChevronLeft, Delete } from '@mui/icons-material';
import FileInput from 'components/inputs/FileInput/FileInput';
import { cloneDeep } from 'lodash';

const DOC_KEY = 'forms';

export default function Documents({ form, onBack, onError, onContinue }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const [files, setFiles] = useState(form.documents ?? { [DOC_KEY]: [] });

    async function handleContinue(data) {
        setSubmitting(true);
        try {
            const response = await axios.patch(
                `${ApiResource.endpoint}/self-employment`,
                {
                    step: 5,
                    documents: files,
                },
                {
                    headers: {
                        'x-auth': form.authToken,
                    },
                },
            );

            onContinue(response.data.form);
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    function handleOnUpload(fArr) {
        if (!fArr) return;
        // name, key, type
        const _files = cloneDeep(files);
        if (!_files[DOC_KEY]) _files[DOC_KEY] = [];
        _files[DOC_KEY].push(...fArr);
        setFiles(_files);
    }

    function handleRemoveFile(key) {
        const _files = cloneDeep(files);
        _files[DOC_KEY] = _files[DOC_KEY].filter((file) => file.key !== key);
        setFiles(_files);
    }

    async function getPresignedUrl(filename, filetype) {
        const response = await axios.post(
            `${ApiResource.endpoint}/self-employment/presigned/url`,
            {
                filename,
                filetype,
            },
            {
                headers: {
                    'x-auth': form.authToken,
                },
            },
        );

        return response.data.url;
    }

    return (
        <form>
            <Box className={classes.root}>
                <Typography variant="h5" fontWeight={'400'} sx={{ marginBottom: 1 }}>
                    Document Submission
                </Typography>
                <Typography variant="font2" fontWeight={'400'} sx={{ marginBottom: 4 }}>
                    Add some description text about why we are asking for this information. Add some description text about why we are
                    asking for this information. Add some description text about why we are asking for this information. Add some
                    description text about why we are asking for this information.
                </Typography>
                <input type="submit" style={{ display: 'none' }} />
                <Box sx={{ marginTop: 3 }} className={classes.form}></Box>

                <FileInput
                    onUpload={handleOnUpload}
                    classes={{ dropBox: classes.dropBox }}
                    appendKey={`self-employment/${form.id}/`}
                    presigned={!!getPresignedUrl}
                    onGetPresigned={getPresignedUrl}
                />

                {files?.[DOC_KEY]?.length > 0 && (
                    <List>
                        {files?.[DOC_KEY]?.map((file, i) => {
                            return (
                                <ListItem key={`${file.name}-${i}`} className={classes.docListItem}>
                                    <ListItemText primary={file.filename} />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => handleRemoveFile(file.key)}>
                                            <Delete />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                )}

                <Box className={classes.controls} sx={{ marginTop: 6 }}>
                    <Button onClick={onBack} startIcon={<ChevronLeft />}>
                        Back
                    </Button>
                    <LoadingButton loading={submitting} onClick={handleContinue} variant="contained">
                        Continue
                    </LoadingButton>
                </Box>
            </Box>
        </form>
    );
}
